export const AmpiConfig = {
  new:{
    tab:'Nuevo valor',
    action:'Guardar',
    msg:'El valor fue agregado exitosamente.',
    err:'Error al agregar el valor.'
  },
  edit:{
    tab:'Modificar valor',
    action:'Guardar',
    msg:'El valor fue modificado exitosamente.',
    err:'Error al modificar el valor.'
  }
}

export const BannerConfig = {
  new:{
    tab:'Nuevo banner',
    action:'Guardar',
    msg:'El banner fue agregado exitosamente.',
    err:'Error al agregar el banner.'
  },
  edit:{
    tab:'Modificar banner',
    action:'Guardar',
    msg:'El banner fue modificado exitosamente.',
    err:'Error al modificar el banner.'
  }
}

export const EditorialConfig = {
  new:{
    tab:'Nueva editorial',
    action:'Guardar',
    msg:'El registro fue agregado exitosamente.',
    err:'Error al agregar el registro.'
  },
  edit:{
    tab:'Modificar editorial',
    action:'Guardar',
    msg:'El registro fue modificado exitosamente.',
    err:'Error al modificar el registro.'
  }
}

export const ConveniosConfig = {
  new:{
    tab:'Nuevo conveio',
    action:'Guardar',
    msg:'El registro fue agregado exitosamente.',
    err:'Error al agregar el registro.'
  },
  edit:{
    tab:'Modificar conveio',
    action:'Guardar',
    msg:'El registro fue modificado exitosamente.',
    err:'Error al modificar el registro.'
  }
}

export const CalendarioConfig = {
  new:{
    tab:'Nuevo evento',
    action:'Guardar',
    msg:'El evento fue agregado exitosamente.',
    err:'Error al agregar el evento.'
  },
  edit:{
    tab:'Modificar evento',
    action:'Guardar',
    msg:'El evento fue modificado exitosamente.',
    err:'Error al modificar el evento.'
  }
}

export const VideosConfig = {
  new:{
    tab:'Nuevo video',
    action:'Guardar',
    msg:'El video fue agregado exitosamente.',
    err:'Error al agregar el video.'
  },
  edit:{
    tab:'Modificar video',
    action:'Guardar',
    msg:'El video fue modificado exitosamente.',
    err:'Error al modificar el video.'
  }
}

export const ContactoConfig = {
  new:{
    tab:'Nuevo contacto',
    action:'Guardar',
    msg:'El contacto fue agregado exitosamente.',
    err:'Error al agregar el contacto.'
  },
  edit:{
    tab:'Modificar contacto',
    action:'Guardar',
    msg:'El contacto fue modificado exitosamente.',
    err:'Error al modificar el contacto.'
  }
}

export const EnlaceConfig = {
  new:{
    tab:'Nuevo enlace',
    action:'Guardar',
    msg:'El enlace fue agregado exitosamente.',
    err:'Error al agregar el enlace.'
  },
  edit:{
    tab:'Modificar enlace',
    action:'Guardar',
    msg:'El enlace fue modificado exitosamente.',
    err:'Error al modificar el enlace.'
  }
}

export const BibliotecaConfig = {
  new:{
    tab:'Nueva biblioteca',
    action:'Guardar',
    msg:'El registro fue agregado exitosamente.',
    err:'Error al agregar el registro.'
  },
  edit:{
    tab:'Modificar biblioteca',
    action:'Guardar',
    msg:'El registro fue modificado exitosamente.',
    err:'Error al modificar el registro.'
  }
}

export const ContactConfig = {
  new:{
    tab:'Nuevo contacto',
    action:'Guardar',
    msg:'El registro fue agregado exitosamente.',
    err:'Error al agregar el registro.'
  },
  edit:{
    tab:'Modificar contacto',
    action:'Guardar',
    msg:'El registro fue modificado exitosamente.',
    err:'Error al modificar el registro.'
  }
}

export const ServiciosConfig = {
  new:{
    tab:'Nuevo servicio',
    action:'Guardar',
    msg:'El servicio fue agregado exitosamente.',
    err:'Error al agregar el servicio.'
  },
  edit:{
    tab:'Modificar servicio',
    action:'Guardar',
    msg:'El servicio fue modificado exitosamente.',
    err:'Error al modificar el servicio.'
  }
}